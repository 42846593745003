<template>
  <div class="myVehicle">
    <div class="nav">
      <div class="nav_title">应用中心</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div class="homeApp">
      <div class="homeApp_top">
        <h4>首页应用</h4>
        <p>
          <span v-if="!isEdit" @click="isEdit = true">编辑</span>
          <span v-if="isEdit" @click="editUserConfigFn">保存</span>
        </p>
      </div>
      <ul class="homeApp_bottom">
        <li v-for="(item, index) in homeApp.config" :key="index">
          <p>
            <img :src="item.img" alt @click.self="toPage(item)" />
          </p>
          <p>{{ item.title }}</p>
          <span v-if="isEdit" @click.stop="deleteListFn(index)">
            <i class="el-icon-remove" />
          </span>
        </li>
      </ul>
    </div>
    <div
      v-for="(item, index) in appLocationList"
      :key="index"
      class="homeCenter"
    >
      <div class="homeCenter_top">
        <h4>{{ item.title }}</h4>
      </div>
      <ul class="homeCenter_bottom">
        <li v-for="(citem, cindex) in item.list" :key="cindex">
          <p>
            <img :src="citem.img" alt @click.self="toPage(citem)" />
          </p>
          <p>{{ citem.title }}</p>
          <span v-if="isEdit" @click.stop="addListFn(citem)">
            <i class="el-icon-circle-plus" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getReportMealConfig } from '@/api/report-meal';
import { getUserConfigAPI, editUserConfigAPI } from '@/api/api';
import eat2 from '@/assets/eat2.png';
import check from '@/assets/check.png';
import Leave from '@/assets/Leave2.png';
import recharge_history from '@/assets/recharge_history.png';
import detail from '@/assets/detail.png';
import allowance2 from '@/assets/allowance2.png';
import inform from '@/assets/inform.png';
import cancel from '@/assets/cancel.png';
// import vip from '@/assets/vip.png'
// import plate from '@/assets/plate.png'
// import park from '@/assets/park.png'
// import conference from '@/assets/conference.png'
// import payment from '@/assets/payment.png'
// import paymentConsumption from '@/assets/paymentConsumption.png'
// import paymentList from '@/assets/paymentList.png'
// import icon_inform from '@/assets/icon-infrom.png'
import { Toast } from 'vant';
import leave3 from '@/assets/leave3.jpg';
// import classCircle from '@/assets/class-circle.png'
// import reportCard from '@/assets/report-card.png'
import examine from '@/assets/examine.jpg';
import paycode from '@/assets/code.png';
import recharge from '@/assets/recharge (2).png';
import faceReview from '@/assets/face-review.png';
import u286 from '@/assets/u286.png';
import u287 from '@/assets/u287.png';
import reportMeal from '@/assets/my-report-meal.png';

export default {
  data() {
    return {
      cancel,
      list: [],
      loading: false,
      finished: false,
      homeApp: {},
      isEdit: false,
      appLocationList: [],
      titleList: [],
      pathList: []
    };
  },
  created() {
    this.displayAppListFn();
    this.getUserConfigFn();
  },
  methods: {
    toBack() {
      this.$router.push('/');
    },
    async toPage(item) {
      if (item.func === 'MyReportMeal') {
        const res = await getReportMealConfig();
        if (!res.data || res.data.open === 0) {
          this.$toast('未开启此功能，若需使用请联系管理员');
          return;
        }
      }
      if (!this.isEdit) {
        this.$router.push({ name: item.func });
      }
    },
    displayAppListFn() {
      const user = JSON.parse(sessionStorage.getItem('personInfo'));
      const isClassManager = user.isClassManager;
      const classManager = user.classManager;
      const role = user.role;
      const departmentManager = user.departmentManager;
      const isManager = user.isManager;
      if (JSON.parse(user.organization.functionalServices).includes(1)) {
        this.appLocationList.push({
          title: '智慧食堂',
          list: [
            { img: allowance2, title: '补助金', func: 'Allowance' },
            { img: eat2, title: '我的订餐', func: 'Order' },
            { img: detail, title: '消费明细', func: 'Consumption' },
            {
              img: recharge_history,
              title: '账单',
              func: 'RechargeHistory'
            },
            { img: paycode, title: '消费码', func: 'Paycode' },
            { img: recharge, title: '充 值', func: 'Recharge' }
          ]
        });
        if (isClassManager || isManager) {
          this.appLocationList.forEach((item) => {
            if (item.title === '智慧食堂') {
              item.list.push({
                img: faceReview,
                title: '人脸审批',
                func: 'FaceCheck'
              });
            }
          });
        }
        if (role === 0) {
          this.appLocationList.forEach((item) => {
            if (item.title === '智慧食堂') {
              item.list.push({
                img: reportMeal,
                title: '我的报餐',
                func: 'MyReportMeal'
              });
            }
          });
        }
      }
      if (JSON.parse(user.organization.functionalServices).includes(2)) {
        this.appLocationList.push({
          title: '智慧门禁',
          list: [
            { img: check, title: '考勤管理', func: 'CheckWork' },
            { img: inform, title: '通知设置', func: 'SmsAuth' },
            { img: Leave, title: '出入记录', func: 'AccessRecord' }
          ]
        });
        if (role === 1) {
          if (classManager) {
            this.appLocationList.map((item) => {
              if (item.title === '智慧门禁') {
                item.list.push(
                  {
                    img: leave3,
                    title: '请假',
                    func: 'Leave'
                  },
                  {
                    img: u286,
                    title: '接送记录',
                    func: 'PickRecords'
                  }
                );
              }
            });
          }
        } else {
          if (departmentManager) {
            this.appLocationList.map((item) => {
              if (item.title === '智慧门禁') {
                item.list.push({
                  img: leave3,
                  title: '请假',
                  func: 'Leave'
                });
              }
            });
          }
        }
        if (isClassManager || isManager) {
          this.appLocationList.forEach((item) => {
            if (item.title === '智慧门禁') {
              item.list.push({
                img: examine,
                title: '审批',
                func: 'ExamineLeave'
              });
              item.list.push({
                img: faceReview,
                title: '人脸审批',
                func: 'FaceCheck'
              });
            }
          });
        }
        if (isClassManager) {
          this.appLocationList.forEach((item) => {
            if (item.title === '智慧门禁') {
              item.list.push({
                img: u287,
                title: '上学管理',
                func: 'ClassInform'
              });
            }
          });
        }
      }
      // if (JSON.parse(user.organization.functionalServices).includes(7)) {
      //   this.appLocationList.push({
      //     title: "智慧停车",
      //     list: [
      //       { img: vip, title: "会员办理", func: "VehicleVip" },
      //       { img: plate, title: "车牌录入", func: "LicensePlate" },
      //       { img: park, title: "停车记录", func: "ParkingRecord" },
      //     ],
      //   });
      // }
      // if (JSON.parse(user.organization.functionalServices).includes(4)) {
      //   this.appLocationList.push({
      //     title: "智慧水电",
      //     list: [
      //       { img: payment, title: "水电费", func: "Payment" },
      //       {
      //         img: paymentConsumption,
      //         title: "水电用量",
      //         func: "PaymentConsumption",
      //       },
      //       { img: paymentList, title: "账单", func: "PaymentList" },
      //     ],
      //   });
      // }
      // if (JSON.parse(user.organization.functionalServices).includes(6)) {
      //   this.appLocationList.push({
      //     title: "智慧办公",
      //     list: [{ img: conference, title: "会议室预约", func: "Conference" }],
      //   });
      // }
      // if (JSON.parse(user.organization.functionalServices).includes(5)) {
      //   this.appLocationList.push({
      //     title: "政企生活",
      //     list: [
      //       {
      //         img: icon_inform,
      //         title: "消息通知",
      //         func: "InformList",
      //       },
      //     ],
      //   });
      // }
      // if (JSON.parse(user.organization.functionalServices).includes(8)) {
      //   console.log(123123123123123);
      //   if (isClassManager || classManager) {
      //     this.appLocationList.push({
      //       title: "校园生活",
      //       list: [
      //         { img: classCircle, title: "班级圈", func: "ClassCircle" },
      //         {
      //           img: icon_inform,
      //           title: "消息通知",
      //           func: "InformList",
      //         },
      //       ],
      //     });
      //     if (classManager) {
      //       this.appLocationList.forEach((item) => {
      //         if (item.title === "校园生活") {
      //           item.list.push({
      //             img: reportCard,
      //             title: "成绩单",
      //             func: "PerformanceQuery",
      //           });
      //         }
      //       });
      //     }
      //   }
      // }
    },
    getUserConfigFn() {
      const user = JSON.parse(sessionStorage.getItem('personInfo'));
      getUserConfigAPI(user.id).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            this.homeApp = res.data;
            this.homeApp.config = JSON.parse(this.homeApp.config);
          } else {
            const arr = [];
            this.homeApp = { config: [] };
            this.appLocationList.map((item) => {
              item.list.map((citem) => {
                arr.push(citem);
              });
            });
            arr.map((item, index) => {
              if (index <= 6) {
                this.homeApp.config.push(item);
              }
            });
            this.$set(this.homeApp, 'organizationId', user.organizationId);
            this.$set(this.homeApp, 'userId', user.id);
          }
          this.appLocationList.map((item) => {
            item.list.map((citem) => {
              this.titleList.push(citem.title);
              this.pathList.push(citem.func);
            });
          });
          let newArr = [];
          this.homeApp.config.map((item, index) => {
            if (
              !this.titleList.includes(item.title) ||
              !this.pathList.includes(item.func)
            ) {
              newArr.push(index);
            }
          });
          newArr = newArr.sort((a, b) => {
            return b - a;
          });
          newArr.map((item) => {
            this.homeApp.config.splice(item, 1);
          });
        }
      });
    },
    editUserConfigFn() {
      const from = JSON.parse(JSON.stringify(this.homeApp));
      from.config = JSON.stringify(from.config);
      editUserConfigAPI({
        ...from
      }).then((res) => {
        if (res.code === 0) {
          this.isEdit = false;
          Toast('编辑成功!');
          this.getUserConfigFn();
        }
      });
    },
    deleteListFn(index) {
      if (this.homeApp.config.length <= 1) {
        Toast('最少需要保留一个应用!');
      } else {
        this.homeApp.config.splice(index, 1);
      }
    },
    addListFn(val) {
      if (this.homeApp.config.length === 7) {
        Toast('最多只能配置7个应用!');
        return;
      }
      let flag = false;
      this.homeApp.config.map((item) => {
        if (item.title === val.title && item.func === val.func) {
          flag = true;
        }
      });
      if (flag) {
        Toast('已存在该应用!');
        return;
      }
      this.homeApp.config.push(val);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .van-field__body {
  display: block;
  box-sizing: border-box;
  padding: 0 5px;
  height: 2.5rem;
  line-height: 2.5rem;
  border: 1px solid rgba(181, 201, 210, 0.6);
}
.myVehicle {
  height: 100%;
  display: flex;
  flex-direction: column;
  //   background: #fff;
  background: rgba(241, 241, 241);
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .homeApp {
    width: 95%;
    background: #ffffff;
    margin: 3.5rem auto 0;
    .homeApp_top {
      display: flex;
      justify-content: space-between;
      color: black;
      margin-top: 0.25rem;
      h4 {
        margin: 0;
        font-weight: 700;
        margin-left: 1.5rem;
      }
      p {
        margin: 0;
        margin-right: 1rem;
        span {
          display: inline-block;
          height: 1.5rem;
          width: 3rem;
          text-align: center;
          background: rgba(18, 150, 219);
          color: white;
          font-weight: 900;
          border-radius: 0.3rem;
          font-size: 0.75rem;
          line-height: 1.5rem;
          z-index: 999;
        }
      }
    }
    .homeApp_bottom {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 0.5rem;
      li {
        width: 25%;
        text-align: center;
        position: relative;
        p {
          margin: 0;
          font-size: 14px;
        }
        p:nth-child(1) {
          width: 100%;
          margin: 1.5rem 0 0;
          img {
            width: 35%;
            height: 35%;
          }
        }
        span {
          position: absolute;
          display: inline-block;
          right: 0.5rem;
          top: 0.5rem;
          height: 1.2rem;
          width: 1.2rem;
          color: rgba(235, 9, 39);
          border-radius: 0.6rem;
          text-align: center;
          line-height: 1.2rem;
          font-size: 1.5rem;
        }
      }
    }
  }
  .homeCenter {
    width: 95%;
    background: #ffffff;
    margin: 1rem auto 0;
    .homeCenter_top {
      display: flex;
      justify-content: space-between;
      color: black;
      margin-top: 0.25rem;
      h4 {
        margin: 0;
        font-weight: 700;
        margin-left: 1.5rem;
      }
      p {
        margin: 0;
        margin-right: 1rem;
      }
    }
    .homeCenter_bottom {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 0.5rem;
      li {
        width: 25%;
        text-align: center;
        position: relative;
        p {
          margin: 0;
          font-size: 14px;
        }
        p:nth-child(1) {
          width: 100%;
          margin: 1.5rem 0 0;
          img {
            width: 35%;
            height: 35%;
          }
        }
        span {
          position: absolute;
          display: inline-block;
          right: 0.5rem;
          top: 0.5rem;
          height: 1.2rem;
          width: 1.2rem;
          color: rgba(18, 150, 219);
          border-radius: 0.6rem;
          text-align: center;
          line-height: 1.2rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
